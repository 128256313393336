import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, CardContent, CardMedia, Typography, CardActions, Button, Container, CircularProgress, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPurchasedCourses } from '../course/courseslice/myCourseSlice'; // Adjust path as necessary

const MyCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { purchasedCourses, loading, error } = useSelector((state) => state.myCourse);
  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    if (userDetails?.uid) {
      dispatch(fetchPurchasedCourses(userDetails.uid));
    }
  }, [dispatch, userDetails]); // ✅ Added userDetails to dependency array

  const handleViewCourse = (courseId) => {
    navigate(`/courses/${courseId}`, { state: { from: location } });
  };

  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center', fontWeight: 700 }}>
        My Courses
      </Typography>

      {loading ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
          <CircularProgress />
        </Grid>
      ) : error ? (
        <Typography color="error" sx={{ textAlign: 'center', mt: 4 }}>
          ❌ Something went wrong. Please try again.
        </Typography>
      ) : purchasedCourses.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: 'center', color: 'gray', mt: 4 }}>
          📌 You haven't purchased any courses yet.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {purchasedCourses.map((course) => (
            <Grid item key={course.id} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  boxShadow: '0px 8px 16px rgba(255, 153, 0, 0.2)',
                  backgroundColor: '#1E293B',
                  color: '#FFFFFF',
                  borderRadius: '12px',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 12px 24px rgba(255, 153, 0, 0.3)' },
                }}
              >
                {course.thumbnail && (
                  <CardMedia
                    component="img"
                    height="160"
                    image={course.thumbnail}
                    alt={course.title}
                    sx={{ borderRadius: '12px 12px 0 0' }}
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h6" sx={{ fontWeight: 'bold' }}>
                    {course.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ color: '#E0E0E0' }}>
                    {course.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#FF9900',
                      color: '#000',
                      fontWeight: 'bold',
                      '&:hover': { backgroundColor: '#CC7A00' },
                    }}
                    onClick={() => handleViewCourse(course.id)}
                  >
                    View Course
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default MyCourses;
