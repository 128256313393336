import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00D4FF',  // Bright cyan-blue for contrast
    },
    secondary: {
      main: '#FF9900',  // Bright orange for CTA buttons
    },
    background: {
      default: 'linear-gradient(180deg, #0B1120 0%, #0F172A 100%)', // Smooth dark gradient
      paper: '#1E293B',  // Slightly lighter than default for contrast
    },
    text: {
      primary: '#E2E8F0',  // Slightly softer white for readability
      secondary: '#94A3B8',  // Lighter grey for subtitles
    },
    error: {
      main: '#FF5252', // Red for errors
    },
    success: {
      main: '#16A34A', // Green for success messages
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#FFFFFF',
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: '#E2E8F0',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: '8px', // Rounded buttons for a modern feel
  //         padding: '10px 20px',
  //         fontSize: '1rem',
  //         fontWeight: 'bold',
  //         '&:hover': {
  //           backgroundColor: '#CC7A00', // Darker orange on hover
  //         },
  //       },
  //     },
  //   },
  //   MuiCard: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: '#1E293B',
  //         color: '#FFFFFF',
  //         boxShadow: '0px 4px 12px rgba(255, 153, 0, 0.2)', // Subtle glow for cards
  //       },
  //     },
  //   },
  // },
});

export default theme;
