import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';

const HeroSection = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #0B1120 0%, #0F172A 100%)',
        pt: { xs: 6, sm: 8, md: 10 }, // Adjust padding for responsiveness
        pb: { xs: 4, sm: 6, md: 8 },
        textAlign: 'center',
      }}
    >
      <Container maxWidth="sm"> 
        <Typography
          component="h1"
          variant="h3" // Reduce size on smaller screens
          color="text.primary"
          gutterBottom
          sx={{
            fontWeight: 700,
            letterSpacing: '1px',
            textShadow: '0 0 15px rgba(255, 153, 0, 0.2)',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, // Responsive font sizes
          }}
        >
          Welcome to DSVCoding
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          paragraph
          sx={{
            maxWidth: '600px',
            margin: '0 auto',
            fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' }, // Adjust paragraph size
          }}
        >
          Learn complete software development with our comprehensive courses.
        </Typography>

        {/* CTA Buttons */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons on small screens
            alignItems: 'center',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF9900',
              color: '#000',
              fontSize: '1rem',
              fontWeight: 'bold',
              px: 4,
              py: 1.5,
              boxShadow: '0px 4px 12px rgba(255, 153, 0, 0.5)',
              width: { xs: '100%', sm: 'auto' }, // Full width on mobile
              '&:hover': {
                backgroundColor: '#CC7A00',
                transform: 'scale(1.05)',
              },
            }}
          >
            Get Started 🚀
          </Button>

          <Button
            variant="outlined"
            sx={{
              color: '#FF9900',
              borderColor: '#FF9900',
              fontSize: '1rem',
              fontWeight: 'bold',
              px: 4,
              py: 1.5,
              mt: { xs: 2, sm: 0 }, // Add margin-top for stacked buttons on mobile
              ml: { sm: 2 }, // Add margin-left only on non-mobile screens
              width: { xs: '100%', sm: 'auto' }, // Full width on mobile
              '&:hover': {
                borderColor: '#CC7A00',
                color: '#CC7A00',
                transform: 'scale(1.05)',
              },
            }}
          >
            Learn More
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;
