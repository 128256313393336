import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, CardActions, Button, Container, Box, CardMedia, Alert, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from './courseslice/allCoursesSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const CourseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { courses, status, error } = useSelector((state) => state.courses);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCourses());
    }
  }, [status, dispatch]);

  const handleViewCourse = (courseId) => {
    navigate(`/courses/${courseId}`, { state: { from: location } });
  };

  if (status === 'loading') {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
        <CircularProgress sx={{ color: '#FF9900' }} />
      </Grid>
    );
  }

  if (status === 'failed') {
    return (
      <Container sx={{ py: 6, textAlign: 'center' }}>
        <Alert severity="error" sx={{ backgroundColor: '#1E293B', color: '#FF5252', fontWeight: 'bold' }}>
          ⚠️ Recorded Courses Are Coming Soon!
        </Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ py: { xs: 4, sm: 6, md: 8 } }} maxWidth="lg">
      <Typography
        variant="h4"
        sx={{
          color: '#E2E8F0',
          fontWeight: 'bold',
          textAlign: 'center',
          mb: { xs: 3, sm: 4 },
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
        }}
      >
        📚 Available Courses
      </Typography>
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item key={course.id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 5,
                backgroundColor: '#1E293B',
                color: '#FFFFFF',
                borderRadius: '12px',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': { transform: 'scale(1.02)' },
              }}
            >
              {course.thumbnail && (
                <CardMedia
                  component="img"
                  height="160"
                  image={course.thumbnail}
                  alt={course.title}
                  sx={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    objectFit: 'cover',
                  }}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#E2E8F0',
                    fontSize: { xs: '1rem', sm: '1.2rem' },
                  }}
                >
                  {course.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  paragraph
                  sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}
                >
                  {course.description}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'gold',
                      fontWeight: 'bold',
                      textShadow: '0px 0px 8px gold',
                      fontSize: { xs: '1rem', sm: '1.2rem' },
                    }}
                  >
                    ₹{course.discountedPrice}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: 'line-through',
                      color: '#FF6868',
                      fontSize: { xs: '0.85rem', sm: '1rem' },
                    }}
                  >
                    ₹{course.price}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    backgroundColor: '#FF9900',
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    px: { xs: 2, sm: 3 },
                    py: { xs: 1, sm: 1.5 },
                    boxShadow: '0px 4px 12px rgba(255, 153, 0, 0.5)',
                    width: { xs: '100%', sm: 'auto' },
                    '&:hover': {
                      backgroundColor: '#CC7A00',
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => handleViewCourse(course.id)}
                >
                  View Course 🚀
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CourseList;
