import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Container,
  Box,
  CardMedia,
  Dialog,
  Slide,
  Collapse,
  IconButton
} from '@mui/material';
import RegistrationForm from '../../pages/registration/RegistrationForm';
import EventIcon from '@mui/icons-material/Event';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ecom_work from '../../stock/ecom_workshop.png';

const workshops = [
  {
    id: 'react_basics_three',
    title: 'E-Commerce Website Building Bootcamp',
    date: '16-11-2024 to 17-11-2024',
    time: '06:00 PM - 10:00 PM IST',
    price: 499,
    discountedPrice: 2,
    image: ecom_work,
    spotsLeft: 50,
    topics: [
      'React Basics',
      'State Management',
      'Routing with React Router',
      'Plain CSS for styling',
      'Hands-on Project',
      'Live Deployment'
    ]
  }
];

const Workshop = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [blink, setBlink] = useState(true);
  const [bounce, setBounce] = useState(false);
  const [expandedId, setExpandedId] = useState(null);

  const handleOpen = (workshop) => {
    setSelectedWorkshop(workshop);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedWorkshop(null);
  };

  const toggleExpand = (id) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  useEffect(() => {
    const interval = setInterval(() => setBlink((prev) => !prev), 800);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const bounceInterval = setInterval(() => {
      setBounce(true);
      setTimeout(() => setBounce(false), 500);
    }, 1000);
    return () => clearInterval(bounceInterval);
  }, []);

  return (
    <Container sx={{ py: 2, px: { xs: 1, sm: 3 } }} maxWidth="lg">
      <Typography
        variant="h5"
        sx={{
          color: 'black',
          mb: { xs: 2, sm: 3 },
          fontWeight: 'bold',
          fontSize: { xs: '1.2rem', sm: '1.5rem' },
          textAlign: 'center'
        }}
      >
        🚀 Upcoming Workshops
      </Typography>

      <Grid container spacing={{ xs: 2, sm: 3 }}>
        {workshops.map((workshop) => (
          <Grid item key={workshop.id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '12px',
                boxShadow: 6,
                background: 'linear-gradient(145deg, #1a1a1a, #111)',
                border: '1px solid #333',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': { transform: 'scale(1.02)' }
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={workshop.image}
                alt={`${workshop.title} thumbnail`}
                sx={{ objectFit: 'cover' }}
              />

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: '#fff',
                    fontSize: { xs: '0.95rem', sm: '1.2rem' }
                  }}
                >
                  {workshop.title}
                </Typography>

                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                  <EventIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography
                    variant="body2"
                    sx={{ color: '#ccc', fontSize: { xs: '0.8rem', sm: '1rem' } }}
                  >
                    {workshop.date}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                  <AccessTimeFilledIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  <Typography
                    variant="body2"
                    sx={{ color: '#ccc', fontSize: { xs: '0.8rem', sm: '1rem' } }}
                  >
                    {workshop.time}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <LocalOfferIcon sx={{ mr: 1, color: theme.palette.secondary.main }} />
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: 'line-through', mr: 1, color: '#ff6868' }}
                  >
                    ₹{workshop.price}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'gold', fontWeight: 'bold' }}
                  >
                    ₹{workshop.discountedPrice} ONLY - Limited Offer!
                  </Typography>
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    color: 'red',
                    mt: 1,
                    fontWeight: 'bold',
                    opacity: blink ? 1 : 0.5,
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                >
                  🔥 Only Limited Spots! Enroll Now!
                </Typography>

                {/* Expand Button */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: '#00e676',
                      fontWeight: 'bold',
                      fontSize: { xs: '0.85rem', sm: '1rem' }
                    }}
                  >
                    🧠 What You'll Learn
                  </Typography>

                  <IconButton
                    onClick={() => toggleExpand(workshop.id)}
                    sx={{
                      color: '#00e676',
                      transform: expandedId === workshop.id ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease'
                    }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>

                <Collapse in={expandedId === workshop.id}>
                <ul style={{ listStyleType: 'none', paddingLeft: '1.2rem', margin: 0 }}>
  {workshop.topics.map((topic, index) => {
    const emojiMap = {
      'React Basics': '⚛️',
      'State Management': '🛠️',
      'Routing with React Router': '🚦',
      'Plain CSS for styling': '🎨',
      'Hands-on Project': '🚀',
      'Live Deployment': '☁️'
    };
    return (
      <li key={index} style={{ color: '#ccc', fontSize: '0.85rem', marginBottom: 4 }}>
        {emojiMap[topic] || '✅'} {topic}
      </li>
    );
  })}
</ul>

                </Collapse>
              </CardContent>

              <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                <Button
                  size="medium"
                  variant="contained"
                  sx={{
                    backgroundColor: '#ff9900',
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: '0.85rem',
                    transform: bounce ? 'scale(1.05)' : 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    boxShadow: '0 4px 10px rgba(255, 153, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: '#cc7a00',
                      transform: 'scale(1.1)'
                    },
                    py: 1,
                    px: 2,
                    borderRadius: '8px'
                  }}
                  onClick={() => handleOpen(workshop)}
                >
                  🚀 Secure Your Spot Now!
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px',
            boxShadow: '0px 8px 20px rgba(255, 153, 0, 0.4)',
            backgroundColor: '#0F172A',
            color: '#fff'
          }
        }}
      >
        <RegistrationForm selectedWorkshop={selectedWorkshop} handleClose={handleClose} />
      </Dialog>
    </Container>
  );
};

export default Workshop;
