import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container
    sx={{
      py: { xs: 4, sm: 6 },
      color: '#f5f5f5', // or white
      backgroundColor: 'rgba(15, 23, 42, 0.9)', // dark glassy background
      backdropFilter: 'blur(10px)', // optional
      borderRadius: '12px',
      px: { xs: 2, sm: 4 },
    }}
    >
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <h1>Refund Policy</h1>
      <Typography variant="body2" paragraph>
        Last updated on Jul 11 2024
      </Typography>
      <Typography variant="body1" paragraph>
        DSVCoding believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
      </Typography>
      <Typography variant="body1" paragraph>
        Cancellations will be considered only if the request is made within 2-3 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.
      </Typography>
      <Typography variant="body1" paragraph>
        DSVCoding does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.
      </Typography>
      <Typography variant="body1" paragraph>
        In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 2-3 days of receipt of the products.
      </Typography>
      <Typography variant="body1" paragraph>
        In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 2-3 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.
      </Typography>
      <Typography variant="body1" paragraph>
        In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.
      </Typography>
      <Typography variant="body1" paragraph>
        In case of any Refunds approved by DSVCoding, it’ll take 3-5 days for the refund to be processed to the end customer.
      </Typography>
      <h1>Privacy Policy</h1>
      <Typography variant="body1" paragraph>
        DSVCoding ("we", "our", "us") values your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our website, services, and products.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        1.1 Personal Information:
      </Typography>
      <Typography variant="body1" paragraph>
        * Name
        <br />
        * Email address
        <br />
        * Contact details
        <br />
        * Payment information
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        2.1 Personal Information:
      </Typography>
      <Typography variant="body1" paragraph>
        * To provide and manage our services
        <br />
        * To process transactions
        <br />
        * To send you updates and promotional materials
        <br />
        * To respond to your inquiries and provide customer support
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell your personal information. We may share your information with:
      </Typography>
      <Typography variant="body1" paragraph>
        * Service Providers: Third-party vendors who help us operate our services.
        <br />
        * Legal Requirements: When required by law or to protect our rights.
        <br />
        * Business Transfers: In connection with a merger, sale, or asset transfer.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Security
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Your Choices
      </Typography>
      <Typography variant="body1" paragraph>
        * Access and Correction: You can access and update your personal information by logging into your account.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website with an updated effective date.
      </Typography>
      <Typography variant="body1" paragraph>
        DSVCoding is the brand name of the business,  DSVCoding Copying the content from the website is illegal.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;

