import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, TextField, Button, Box, Grid, Link, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUser, loginUser, clearError } from './authSlice';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const AuthPage = ({ isLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = isLogin || location.pathname === '/login';
  const dispatch = useDispatch();
  const { loading, error, isAuthenticated } = useSelector((state) => state.auth);
  const redirectTo = location.state?.from || '/';

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectTo);
    }
  }, [redirectTo, isAuthenticated, navigate]);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const initialValues = {
    email: '',
    password: '',
    userName: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    userName: !isLoginPage ? Yup.string().min(6, 'Username must be at least 6 characters').required('Required') : Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const cred = { email: values.email, password: values.password, userName: values.userName };
    dispatch(!isLoginPage ? createUser(cred) : loginUser(cred));
    setSubmitting(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        background: 'linear-gradient(135deg, #0B1120 30%, #0F172A 70%)',
        display: 'flex',
        flexDirection: 'column',
        px: 2, // Padding for mobile screens
      }}
    >
      <Box
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(30, 41, 59, 0.75)',
          padding: { xs: '30px', sm: '50px' }, // Responsive padding
          borderRadius: '12px',
          boxShadow: '0px 8px 20px rgba(255, 153, 0, 0.3)',
          width: { xs: '100%', sm: '400px' }, // Full width on mobile, fixed on larger screens
          maxWidth: '400px', // Prevents stretching on larger screens
          textAlign: 'center',
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: '#FFFFFF',
            fontWeight: 700,
            textShadow: '0 0 15px rgba(255, 153, 0, 0.2)',
            mb: 2,
            fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Adjusted font size for mobile
          }}
        >
          {isLoginPage ? 'Sign in to your account' : 'Create your account'}
        </Typography>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, errors, touched }) => (
            <Form>
              {!isLoginPage && (
                <Field
                  as={TextField}
                  margin="normal"
                  required
                  fullWidth
                  name="userName"
                  label="Username"
                  type="text"
                  id="userName"
                  error={touched.userName && Boolean(errors.userName)}
                  helperText={touched.userName && errors.userName}

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: '#FF9900' }, // Default border color
                      '&:hover fieldset': { borderColor: '#FF9900' }, // Hover effect
                      '&.Mui-focused fieldset': { 
                        borderColor: '#FF9900', 
                        boxShadow: '0px 0px 8px rgba(255, 153, 0, 0.5)' // Focus glow effect
                      },
                    },
                    input: { color: '#FFFFFF' },
                    label: { color: '#64748B' },
                  }}
                  
                />
              )}

<Field
  as={TextField}
  margin="normal"
  required
  fullWidth
  id="email"
  label="Email Address"
  name="email"
  autoComplete="email"
  autoFocus
  error={touched.email && Boolean(errors.email)}
  helperText={touched.email && errors.email}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: '#FF9900' }, // Default border color
      '&:hover fieldset': { borderColor: '#FF9900' }, // Hover effect
      '&.Mui-focused fieldset': { 
        borderColor: '#FF9900', 
        boxShadow: '0px 0px 8px rgba(255, 153, 0, 0.5)' // Focus glow effect
      },
    },
    input: { color: '#FFFFFF' },
    label: { color: '#64748B' },
  }}
/>


              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: '#FF9900' }, // Default border color
                    '&:hover fieldset': { borderColor: '#FF9900' }, // Hover effect
                    '&.Mui-focused fieldset': { 
                      borderColor: '#FF9900', 
                      boxShadow: '0px 0px 8px rgba(255, 153, 0, 0.5)' // Focus glow effect
                    },
                  },
                  input: { color: '#FFFFFF' },
                  label: { color: '#64748B' },
                }}
              />

              {isLoginPage && (
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate('/forgot-password')}
                      sx={{ color: '#FF9900', fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#FF9900',
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  py: { xs: 1, sm: 1.5 },
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#CC7A00', transform: 'scale(1.05)' },
                }}
                disabled={isSubmitting || loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : isLoginPage ? 'Sign in' : 'Sign up'}
              </Button>

              {error && (
                <Typography variant="body2" color="error" sx={{ textAlign: 'center', mt: 2 }}>
                  {error}
                </Typography>
              )}

              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => navigate(isLoginPage ? '/register' : '/login')}
                    sx={{ color: '#FF9900', fontWeight: 'bold', textDecoration: 'underline' }}
                  >
                    {isLoginPage ? 'Not a member? Sign Up Now' : 'Already have an account? Sign in'}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Grid>
  );
};

export default AuthPage;
