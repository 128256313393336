import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, rgba(15, 23, 42, 0.9), rgba(10, 15, 30, 0.9))',
        backdropFilter: 'blur(8px)',
        color: 'white',
        py: 6,
        boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        {/* Footer Title */}
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: '#FFD700' }}>
          DSVCoding 🚀
        </Typography>

        {/* Copyright Notice */}
        <Typography variant="body2" sx={{ color: '#A0AEC0', mb: 3 }}>
          © 2024 DSVCoding. All rights reserved.
        </Typography>

        {/* Contact Details */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ color: '#E0E0E0', mb: 1 }}>
            📞 Contact Us: 
            <Link
              href="tel:7382542759"
              sx={{
                color: '#FFD700',
                textDecoration: 'none',
                transition: 'all 0.3s ease-in-out',
                '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
              }}
            >
              7382542759
            </Link>
          </Typography>

          <Typography variant="body1" sx={{ color: '#E0E0E0', mb: 1 }}>
            ✉️ Mail Us: 
            <Link
              href="mailto:support@dsvcoding.com"
              sx={{
                color: '#FFD700',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
              }}
            >
              support@dsvcoding.com
            </Link>
          </Typography>

          <Typography variant="body1" sx={{ color: '#E0E0E0', mb: 2 }}>
            📍 Address: 357-3/28 Sri Venkateshwara Colony, Gopalpur, TELANGANA 506009
          </Typography>
        </Box>

        {/* Policies & Links */}
        <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    flexWrap: 'wrap',
    mt: 2,
  }}
>
  <Link
    href="/terms"
    sx={{
      color: '#FFD700',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
    }}
  >
    📜 Terms & Conditions
  </Link>

  <Link
    href="/privacy"
    sx={{
      color: '#FFD700',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
    }}
  >
    🔄 Refund Policies
  </Link>

  <Link
    href="/shippingpolicy"
    sx={{
      color: '#FFD700',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
    }}
  >
    🚚 Shipping Policy
  </Link>

  <Link
  href="mailto:support@dsvcoding.com"
  sx={{
    color: '#FFD700',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': { textDecoration: 'underline', color: '#FFAA33' },
  }}
>
  📩 Contact Us
</Link>

</Box>

      </Container>
    </Box>
  );
};

export default Footer;
