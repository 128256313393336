import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Container, Box, Card, CardContent, CardMedia } from '@mui/material';

const ViewCourse = ({ course, isCoursePurchased }) => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const simplifiedUserDetails = {
    username: userDetails?.username,
    email: userDetails?.email,
    userId: userDetails?.uid,
  };

  const handleEnroll = () => {
    if (!isAuthenticated) {
      // Redirect to register page with previous location data
      navigate('/register', { state: { from: location, userDetails: simplifiedUserDetails } });
    } else {
      // Redirect to payment page with course details
      navigate('/payment', { state: { course: course, userDetails: simplifiedUserDetails } });
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Card
        sx={{
          boxShadow: '0px 8px 20px rgba(255, 153, 0, 0.3)',
          borderRadius: '12px',
          backgroundColor: '#1E293B',
          color: '#FFFFFF',
          overflow: 'hidden',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': { transform: 'scale(1.02)', boxShadow: '0px 12px 24px rgba(255, 153, 0, 0.3)' },
        }}
      >
        <CardMedia
          component="img"
          height="240"
          image={course.thumbnail}
          alt={course.title}
          sx={{ borderRadius: '12px 12px 0 0' }}
        />
        <CardContent>
          {/* Course Title */}
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {course.title}
          </Typography>

          {/* Course Description */}
          <Typography variant="body1" color="text.secondary" paragraph sx={{ textAlign: 'center', color: '#E0E0E0' }}>
            {course.description}
          </Typography>

          {/* Price and Discounted Price */}
          {!isCoursePurchased && (
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#FF9900', mr: 2 }}>
                ₹{course.discountedPrice}
              </Typography>
              <Typography variant="body2" sx={{ textDecoration: 'line-through', color: '#B0BEC5' }}>
                ₹{course.price}
              </Typography>
            </Box>
          )}

          {/* Enroll Now Button */}
          {!isCoursePurchased && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#FF9900',
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  py: 1.5,
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#CC7A00', transform: 'scale(1.05)' },
                }}
                onClick={handleEnroll}
              >
                Enroll Now
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ViewCourse;
