import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography, DialogActions } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StyledTextField from '../../util/StyledTextField';
import { submitRegistration, clearRegistrationState, checkDuplicateRegistration } from './registrationSlice';
import { createOrder } from '../payment/PaymentSlice';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ selectedWorkshop, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, error, duplicateError } = useSelector((state) => state.registration);
  const orderLoading = useSelector((state) => state.pay.loading);

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  useEffect(() => {
    if (success) {
      setSuccessDialogOpen(true);
    }
    return () => {
      dispatch(clearRegistrationState());
    };
  }, [dispatch, success]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    role: Yup.string().required('Please select your role'),
    mobile: Yup.string().required('Please enter your mobile number'),
  });

  const handleSubmit = async (values) => {
    const registrationData = {
      fullName: values.fullName,
      email: values.email,
      mobile: values.mobile,
      role: values.role,
      workshopTitle: selectedWorkshop.title,
      workshopDate: selectedWorkshop.date,
      workshopId: selectedWorkshop.id,
      paymentAmount: selectedWorkshop.discountedPrice,
    };

    try {
      const duplicateCheckResult = await dispatch(checkDuplicateRegistration(registrationData));

      if (checkDuplicateRegistration.rejected.match(duplicateCheckResult)) {
        console.error('Duplicate registration:', duplicateCheckResult.payload);
        return;
      }

      if (checkDuplicateRegistration.fulfilled.match(duplicateCheckResult)) {
        const orderResultAction = await dispatch(
          createOrder({
            amount: selectedWorkshop.discountedPrice,
            currency: 'INR',
            receipt: `receipt_${selectedWorkshop.id}`,
            isWorkshop: true,
          })
        );

        if (createOrder.fulfilled.match(orderResultAction)) {
          navigate('/workshop-payment', { state: { registrationData, workshop: selectedWorkshop } });
        }
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  return (
    <DialogContent
      sx={{
        background: 'rgba(15, 23, 42, 0.9)',
        backdropFilter: { xs: 'blur(5px)', sm: 'blur(10px)' }, // Reduced blur on mobile
        p: { xs: 2, sm: 4 }, // Responsive padding
        borderRadius: '12px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: '#FFD700',
          textAlign: 'center',
          mb: { xs: 2, sm: 3 },
          fontSize: { xs: '1.3rem', sm: '1.5rem' }, // Adjust typography for mobile
        }}
      >
        🚀 Secure Your Spot Now!
      </Typography>

      <Formik initialValues={{ fullName: '', email: '', mobile: '', role: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting, isValid, dirty }) => (
          <Form>
            {/* Full Name Field */}
            <StyledTextField
              name="fullName"
              label="Full Name"
              error={errors}
              touched={touched}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#FFD700' },
                  '&:hover fieldset': { borderColor: '#FFAA33' },
                  '&.Mui-focused fieldset': { borderColor: '#FFD700', boxShadow: '0px 0px 10px rgba(255, 215, 0, 0.8)' },
                },
                input: { color: '#FFFFFF' },
              }}
            />

            {/* Email Field */}
            <StyledTextField
              name="email"
              label="Email Address"
              error={errors}
              touched={touched}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#FFD700' },
                  '&:hover fieldset': { borderColor: '#FFAA33' },
                  '&.Mui-focused fieldset': { borderColor: '#FFD700', boxShadow: '0px 0px 10px rgba(255, 215, 0, 0.8)' },
                },
                input: { color: '#FFFFFF' },
              }}
            />

            {/* Mobile Number Field */}
            <StyledTextField
              name="mobile"
              label="Mobile Number"
              error={errors}
              touched={touched}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#FFD700' },
                  '&:hover fieldset': { borderColor: '#FFAA33' },
                  '&.Mui-focused fieldset': { borderColor: '#FFD700', boxShadow: '0px 0px 10px rgba(255, 215, 0, 0.8)' },
                },
                input: { color: '#FFFFFF' },
              }}
            />

            {/* Role Selection */}
            <FormControl fullWidth margin="normal" error={touched.role && Boolean(errors.role)}>
              <InputLabel sx={{ color: '#FFD700' }}>I am a...</InputLabel>
              <Field as={Select} name="role" label="I am a..." fullWidth sx={{ color: '#fff', backgroundColor: '#1E293B' }}>
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="softwareEngineer">Software Engineer</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Field>
              {touched.role && errors.role && (
                <Typography variant="body2" color="error">
                  {errors.role}
                </Typography>
              )}
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting || loading || !isValid || !dirty}
              sx={{
                mt: 3,
                mb: 2,
                py: { xs: 1, sm: 1.5 },
                fontSize: { xs: '1rem', sm: '1.2rem' },
                fontWeight: 'bold',
                backgroundColor: isValid ? '#FFD700' : 'rgba(255, 215, 0, 0.3)',
                color: isValid ? '#000' : '#FFF',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#FFAA33', transform: 'scale(1.05)' },
              }}
            >
              {loading || orderLoading ? <CircularProgress size={24} color="inherit" /> : '🚀 Register Now'}
            </Button>

            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button variant="outlined" sx={{ borderColor: '#FFD700', color: '#FFD700', '&:hover': { backgroundColor: '#FFD700', color: '#000' } }} onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
};

export default RegistrationForm;
