import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase'; // Import Firebase auth instance

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    if (!email.trim()) return;

    setLoading(true);
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('✅ If your email is registered, you will receive a password reset link. Check your inbox.');
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        background: 'linear-gradient(135deg, #0B1120 30%, #0F172A 70%)',
        px: 2, // Padding for mobile screens
      }}
    >
      <Box
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(30, 41, 59, 0.75)',
          padding: { xs: '30px', sm: '50px' }, // Responsive padding
          borderRadius: '12px',
          boxShadow: '0px 8px 20px rgba(255, 153, 0, 0.3)',
          width: { xs: '100%', sm: '400px' }, // Full width on mobile, fixed on larger screens
          maxWidth: '400px', // Prevents stretching on large screens
          textAlign: 'center',
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: '#FFFFFF',
            fontWeight: 700,
            textShadow: '0 0 15px rgba(255, 153, 0, 0.2)',
            mb: 2,
            fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Adjusted font size for mobile
          }}
        >
          Reset Your Password
        </Typography>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ style: { color: '#64748B' } }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#FF9900' }, // Default border color
              '&:hover fieldset': { borderColor: '#FF9900' }, // Hover effect
              '&.Mui-focused fieldset': { 
                borderColor: '#FF9900', 
                boxShadow: '0px 0px 8px rgba(255, 153, 0, 0.5)' // Focus glow effect
              },
            },
            input: { color: '#FFFFFF' },
            label: { color: '#64748B' },
          }}
        />

        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2, fontWeight: 'bold' }}>
            ❌ {error}
          </Typography>
        )}

        {message && (
          <Typography variant="body2" sx={{ mt: 2, color: '#00C853', fontWeight: 'bold' }}>
            {message}
          </Typography>
        )}

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: email.trim() ? '#FF9900' : '#A0A0A0', // Disabled button color
            color: '#000',
            fontWeight: 'bold',
            fontSize: { xs: '1rem', sm: '1.1rem' },
            py: { xs: 1, sm: 1.5 }, // Adjusted padding for mobile
            borderRadius: '8px',
            '&:hover': { 
              backgroundColor: email.trim() ? '#CC7A00' : '#A0A0A0', 
              transform: email.trim() ? 'scale(1.05)' : 'none' 
            },
          }}
          onClick={handlePasswordReset}
          disabled={!email.trim() || loading} // Disable button if email is empty
        >
          {loading ? 'Sending...' : 'Send Password Reset Email'}
        </Button>

        <Button
          type="button"
          fullWidth
          variant="text"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '0.9rem', sm: '1rem' },
            color: '#4B6EF5',
            textDecoration: 'underline',
            '&:hover': { color: '#FF9900' },
          }}
          onClick={() => navigate('/login')}
        >
          Back to Sign In
        </Button>
      </Box>
    </Grid>
  );
};

export default ForgotPassword;
