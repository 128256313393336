import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, userDetails, adminOnly, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // 🔹 If adminOnly is true, check for admin email
  if (adminOnly && userDetails?.email !== 'testtest123@gmail.com') {
    return <Navigate to="/" replace />; // Redirect unauthorized users to home
  }

  return children;
};

export default ProtectedRoute;
