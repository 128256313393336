import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Typography, Box, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { validateOrder, resetOrder } from '../../../pages/payment/PaymentSlice';
import { sendEmailConfirmation, submitRegistration } from '../../../pages/registration/registrationSlice';

const WorkshopPayment = () => {
  const scriptRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { registrationData, workshop } = location.state || {};
  const { orderId, error, validationError, isValid, loading, validationLoading, isDialogOpen } = useSelector((state) => state.pay);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [razorpayScriptLoaded, setRazorpayScriptLoaded] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (orderId) {
      loadRazorpayScript();
    }
  }, [orderId]);

  useEffect(() => {
    if (isValid) {
      const emailData = {
        recipientEmail: registrationData.email,
        name: registrationData.fullName,
        workshopTitle: registrationData.workshopTitle,
        paymentAmount: registrationData.paymentAmount,
        isWorkshop: true,
      };
      setDialogOpen(isDialogOpen);
      dispatch(resetOrder());
      dispatch(submitRegistration(registrationData));
      dispatch(sendEmailConfirmation(emailData));
    }
  }, [isValid, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrder());
    };
  }, [dispatch]);

  const loadRazorpayScript = () => {
    if (scriptRef.current) {
      openRazorpay();
      return;
    }

    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      scriptRef.current = true;
      setRazorpayScriptLoaded(true);
      openRazorpay();
    };

    document.body.appendChild(script);
  };

  const openRazorpay = () => {
    if (orderId) {
      const options = {
        key: process.env.RZP_KEY_ID,
        amount: (workshop.discountedPrice * 100).toString(),
        currency: "INR",
        name: process.env.BUSINESS_NAME,
        description: `Payment for ${workshop.title}`,
        order_id: orderId,
        handler: function (response) {
          dispatch(validateOrder({
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            fullName: registrationData.fullName,
            email: registrationData.email,
            mobile:registrationData.mobile,
            workshopId: workshop.id,
            isWorkshop: true
          }));
        },
        prefill: {
          name: registrationData.fullName,
          email: registrationData.email,
          contact: registrationData?.mobile
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert(`Error: ${response.error.description}`);
      });

      rzp1.open();
    }
  };

  if (!workshop || !registrationData) {
    return <Typography variant="h6">Workshop or registration details not available.</Typography>;
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Payment Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your payment has been verified successfully! You are now registered for the bootcamp. You will get a confirmation email soon.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {!razorpayScriptLoaded ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Card
          sx={{
            backgroundColor: '#1E293B',
            color: '#FFFFFF',
            p: { xs: 3, sm: 4 },
            borderRadius: '12px',
            boxShadow: '0px 6px 20px rgba(255, 153, 0, 0.3)',
            textAlign: 'center',
          }}
        >
          <CardContent>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#FFD700' }}>
              {workshop.title}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph sx={{ color: '#E2E8F0' }}>
              {workshop.description}
            </Typography>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{
                mt: 2,
                backgroundColor: '#0F172A',
                padding: 2,
                borderRadius: '8px',
              }}
            >
              <Typography variant="h5" color="gold" fontWeight="bold">
                ₹{workshop.discountedPrice}
              </Typography>
              <Typography variant="body2" color="error" sx={{ textDecoration: 'line-through', fontSize: '1.2rem' }}>
                ₹{workshop.price}
              </Typography>
            </Box>

            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
            {validationError && <Typography color="error" sx={{ mt: 2 }}>{validationError}</Typography>}

            {loading || validationLoading ? <CircularProgress sx={{ mt: 2 }} size={24} /> : null}

            {/* Buttons Section */}
            <Box mt={3} display="flex" flexDirection="column" gap={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: '#FF9900',
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  py: 1.5,
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#CC7A00' },
                }}
                onClick={openRazorpay}
              >
                Continue Payment
              </Button>

              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: '#FF9900',
                  color: '#FF9900',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  py: 1.5,
                  borderRadius: '8px',
                  '&:hover': { borderColor: '#CC7A00', color: '#CC7A00' },
                }}
                onClick={() => navigate('/workshop')}
              >
                Back to Workshops
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default WorkshopPayment;
