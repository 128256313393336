import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Accordion, AccordionSummary, AccordionDetails, Typography, Box, CircularProgress, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewCourse from './ViewCourse';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player/vimeo';
import { useTheme } from '@mui/material/styles';
import { fetchPurchasedCourses, findCourseById } from './courseslice/myCourseSlice';

const CoursePage = () => {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { courses } = useSelector((state) => state.courses);
  const { purchasedCourses, loading, error, courseDetails } = useSelector((state) => state.myCourse);
  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    if (userDetails?.uid) {
      dispatch(fetchPurchasedCourses(userDetails.uid));
    } else {
      dispatch(findCourseById(courseId));
    }
  }, [dispatch, userDetails, courseId, purchasedCourses]); // ✅ Ensures data updates properly

  const course = courses.find((c) => c.id === courseId) || courseDetails?.course;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </Box>
    );
  }

  if (!course) {
    return (
      <Container sx={{ textAlign: 'center', mt: 6 }}>
        <Alert severity="error" sx={{ backgroundColor: '#1E293B', color: '#FF5252', fontWeight: 'bold' }}>
          ⚠️ Course not found.
        </Alert>
      </Container>
    );
  }

  const isCoursePurchased = purchasedCourses.some((purchasedCourse) => purchasedCourse.id === courseId);

  return (
    <Container sx={{ mt: 8 }} maxWidth="md">
      <ViewCourse course={course} isCoursePurchased={isCoursePurchased} />
      <Box sx={{ mt: 4 }}>
        {course.sections.map((section, index) => (
          <Accordion
            key={index}
            sx={{
              mb: 2,
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': { transform: 'scale(1.02)', boxShadow: '0px 4px 12px rgba(255, 153, 0, 0.3)' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.secondary }} />}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': { backgroundColor: '#1E293B' },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.background.default,
                padding: '16px',
                borderRadius: '8px',
                transition: 'background-color 0.3s ease-in-out',
              }}
            >
              {section.videos.map((video, vidIndex) => (
                <Box
                  key={vidIndex}
                  sx={{
                    mb: 3,
                    p: 2,
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '8px',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out',
                    '&:hover': {
                      boxShadow: isCoursePurchased ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
                    },
                    opacity: isCoursePurchased ? 1 : 0.4, // Dim the box if not purchased
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1, color: theme.palette.text.primary }}>
                    {video.title}
                  </Typography>
                  {isCoursePurchased ? (
                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        paddingTop: '56.25%', // 16:9 Aspect Ratio
                        height: 0,
                        '&:hover': { boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' },
                      }}
                    >
                      <ReactPlayer
                        url={`https://vimeo.com/${video.vimeoId}`}
                        width="100%"
                        height="100%"
                        controls
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          borderRadius: '8px',
                          width: '100%',
                          height: '100%',
                          maxWidth: '800px',
                          margin: 'auto',
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontStyle: 'italic' }}>
                      Purchase this course to view the video.
                    </Typography>
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default CoursePage;
